ul, li {
  margin: 0;
  padding: 0;
  list-style: none; 
}

h1, h2, h3, h4, h5, h6 {

  font-size: inherit;

}

body {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #222222;;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
}

.logo-link {
  display: inline-block;
}

.logo-image {
  width: 100px;  
  height: auto;
  display: block;
}

.navbar {
  display: flex;
  gap: 20px;
  align-items: center; 
}

.navbar a,
.dropdown-toggle {
  color: #222222;
  text-decoration: none;
  font-size: 18px;
  padding: 5px 10px;
  font-weight: 600;
  position: relative;
}

.navbar a::after,
.dropdown-toggle::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 0; 
  height: 2px;
  background-color: #C72538;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.navbar a.active-link::after,
.navbar a:hover::after,
.dropdown-toggle:hover::after {
  width: 100%;
}


.dropdown-menu a::after {
  content: none;
}


.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 100%; 
}

.dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
  padding: 10px 0;
}

.dropdown.open .dropdown-menu {
  display: block;
}

.dropdown-menu a {
  display: block;
  padding: 10px 20px;
  color: #222222;
  text-decoration: none;
  font-size: 16px;
  white-space: nowrap;
}

.dropdown-menu a:hover {
  color: #C72538;
}

.contact-button {
  background-color: #C72538;
  color: white;
  padding: 13px 30px;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 26px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.contact-button::after {
  content: "\f061"; 
  font-family: 'FontAwesome';
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
  transition: margin-left 0.3s ease;
}

.contact-button:hover {
  background-color: #a91f2d;
}

.contact-button:hover::after {
  margin-left: 12px; 
}

.contact-title {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin: 60px 0px 30px 0px;
  text-align: center;
}


.home {
  position: relative;
  text-align: center;
  color: white;
}

.home-background {
  position: relative;
}

.home-bg-image {
  width: 100%;
  height: calc(100vh - 80px);
  object-fit: cover;
}

.home-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 98.36%);
  z-index: 1;
}

.home-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  z-index: 2;
}

.home-content h1 {
  font-size: 70px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 80px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}

.home-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}


.image-overlay-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0;
  position: relative;
}

.image-container {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.first-image {
  left: -35%;
  position: relative;
  z-index: 1;
}

.second-image {
  position: absolute;
  top: 30%; 
  left: 40%; 
  z-index: 2;
}

@media (max-width: 1370px) {

  .first-image {
    width: 300px;
    height: 300px;
  }

  .second-image img{
    width: 300px;
    height: 300px;
  }
}


.second-image::before {
  content: "";
  position: absolute;
  top: -8px;
  left: -8px;
  right: 8px;
  bottom: 8px;
  background-color: #fff;
  z-index: -1;
}

.text-container {
  width: 50%;
  padding-left: 40px;
}

.text-container .subtitle {
  font-size: 18px;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.title-container {
  position: relative;
    display: block;
    margin-top: 35px;
}

.title-container h2 {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin: 10px;
}

.title-container h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: rgba(34, 34, 34, 0.2);
  position: absolute;
  top: -125%;
  left: -21px;
  z-index: -1;
}

@media (max-width: 1460px) {

  .title-container h1 {
    top: -60%;
  }
}

.text-container p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #777777;
  width: 600px;
  margin-bottom: 40px;
}

.learn-more-button {
  background-color: #fff;
  color: #222222;
  padding: 13px 30px;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 26px;
  font-weight: 600;
  border: 2px solid #EAEAEA;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.learn-more-button::after {
  content: "\f061"; 
  font-family: 'FontAwesome';
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
  transition: margin-left 0.3s ease;
}

.learn-more-button:hover {
  background-color: #C72538;
}

.learn-more-button:hover::after {
  margin-left: 12px; 
}

.criteria {
  padding: 30px 20px;
  max-width: 1320px;
  margin: 0 auto;
  text-align: center;
}

.criteri-title {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin: 30px 0px ;
  text-align: center;
  width: 800px;
}

.nominations {
  padding: 50px 20px;
  text-align: center;
}

.nominations-cont {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.nominations-selector {
  padding: 0px 0px 30px 0px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  flex-direction: column;
  width: 250px;
}

.nominations-selector-cont {
  display: flex;
  /* align-items: center; */
}

.nominations-button {
  background: #C72538;
  border: 2px solid white;
  color: white;
  padding: 10px 25px;
  text-transform: uppercase;
    line-height: 26px;
    font-weight: 600;
    font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.nominations-button.active,
.nominations-button:hover {
  background: #222222;
  color: #fff;
}

.nominations-text-container {
  position: relative;
    display: block;
    margin-top: 35px;
}

.nominations-text-container h2 {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin: 10px;
}

.nominations-text-container h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: rgba(34, 34, 34, 0.2);
  position: absolute;
  top: -55%;
  left: -21px;
  z-index: -1;
}



.nominations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.nomination-card {
  background-color: #fff;
  border: 2px solid #EAEAEA;
  padding: 52px 40px;
  transition: transform 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.nomination-card:hover {
  transform: translateY(-5px);
  border-color: #C72538;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.nomination-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  transition: transform 0.3s ease; 
}

.nomination-card:hover .nomination-icon {
  transform: scale(1.2);
}

.nomination-title {
  font-size: 20px;
  color: #222;
  margin-bottom: 10px;
}

.nomination-description {
  font-size: 18px;
  color: #777;
  margin-bottom: 20px;
}

.read-more {
  font-size: 14px;
  color: #C72538;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

.read-more:hover {
  text-decoration: underline;
}

.criteria-list {
  padding-left: 20px;
  margin: 20px 0;
}

.criteria-list__item {
  font-size: 18px;
  line-height: 1.6;
  text-align: start;
  margin-bottom: 10px;
  color: #555;
  list-style: none;
  position: relative;
}

.criteria-list__item::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px; 
  background-color: #C72538; 
  border-radius: 50%; 
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%); 
}


@media (max-width: 768px) {
  .criteria-list__item {
    font-size: 14px;
  }
}



.regulations {
  padding: 60px 20px;
  text-align: start;
}

.regulations-content {
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin: 30px 0px ;
  text-align: center;
}

.regulations-list {
  padding-left: 20px;
  margin: 20px 0;
  font-size: 18px;
  line-height: 1.8;
  color: #555;
}

.regulations-item {
  margin-bottom: 20px;
}

.regulations-item strong {
  font-size: 24px;
  color: #C72538;
  display: block;
  margin-bottom: 10px;
}

.sub-list {
  padding-left: 40px;
  list-style-type: none;
}

.sub-list li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.sub-list li::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #C72538;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.sub-text {
  font-size: 20px;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #555;
    font-weight: 400;
}

/* About */

.image-text-section {
  display: flex;
  align-items: center;
  max-width: 1320px;
  margin: 0 auto;
  padding: 50px 20px 0px 20px;
}

.image-text-section__content {
  flex: 1;
  padding-right: 20px;
}

.image-text-section__content h2 {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin: 10px 0px;
}

.image-text-section__content p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #777777;
  width: 600px;
}


.image-text-section__image {
  flex: 1;
  /* padding-left: 20px; */
}

.image-text-section__image img {
  width: 100%;
  height: auto;
}

.about-section {
  margin: 150px 0px 60px 0px;
  text-align: center;
}

.about-section_2 {
  margin: 60px 0px;
  text-align: center;
}

.title {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin: 20px 0px ;
}

.about-text {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #777777;
  padding-bottom: 20px;
  width: 900px; 
  margin: 0 auto;
}

/* Page-header */

.page-header {
  position: relative;
  width: 100%;
  height: 400px; 
  overflow: hidden;
}

.page-header__bg {
  position: relative;
  width: 100%;
  height: 100%;
}

.page-header__bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-header__bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 98.36%);
  z-index: 1;
}

.page-header__content {
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 20px;
  color: white;
}

.page-header__content h1 {
  font-weight: 700;
    font-size: 80px;
    line-height: 80px;
    letter-spacing: -0.02em;
    margin: 10px 0px;
}

.page-header__content p {
  margin: 0;
  font-size: 1.2rem;
}

.breadcrumb {
  margin-top: 10px;
  font-size: 0.9rem;
}

.breadcrumb a {
  color: white;
  text-decoration: none;
  margin-right: 5px;
}

.breadcrumb span {
  color: #ccc;
}

.winners-page {
  padding: 20px;
}

.winners-header {
  font-weight: 700;
  max-width: 1400px;
  margin: 0 auto;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  
}

.winners-header h2{
  margin: 20px 10px;
}

.year-selector {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.year-button {
  background: #C72538;
  border: 2px solid white;
  color: white;
  padding: 10px 25px;
  text-transform: uppercase;
    line-height: 26px;
    font-weight: 600;
    font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.year-button.active,
.year-button:hover {
  background: #222222;
  color: #fff;
}

.winners-list {
  margin-top: 40px;
}

.winner-item {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.winner-year {
  flex: 1;
  font-weight: bold;
}


.winner-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-right: 20px;
  margin-bottom: 5px;
  color: #fff;
  width: 400px;
}

.winner-details {
  flex: 3;
  
}

.winner-details-text{
  text-align: right;
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 400;
  margin-right: 20px;
}

.winner-details-text span{
  font-weight: bold;
}

.winners-title {
  text-align: center;
  margin-bottom: 20px 0px;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
}


.gallery {
  margin-top: 50px;
  text-align: center;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}

.gallery-grid img {
  width: 100%;
  height: 280px;
  border-radius: 10px;
}

/* Gradpri */

.grandpri-container {
  padding: 50px 0px;
}

.grand-container {
  width: 50%;
    padding-left: 40px;
}

.grand-container p{
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555;
}

.grand-title-container {
  position: relative;
    display: block;
    margin-top: 35px;
}

.grand-title-container h2 {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin: 10px;
}

.grand-title-container h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: rgba(34, 34, 34, 0.2);
  position: absolute;
  top: -60%;
  left: -21px;
  z-index: -1;
}

.grandpri-section__image {
  flex: 1;
  padding-left: 20px;
}

.grandpri-section__image img {
  width: 100%;
  height: 750px;
}

.grandpri-title-container {
  text-align: center;
  margin: 60px 0;
}


.grandpri-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 20px;
  background: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.grandpri-container p {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555;
}

.grandpri-list {
  margin-top: 40px;
  padding: 0px 100px;
}

.grandpri-item {
  padding: 20px 0;
  border-bottom: 1px solid #EAEAEA;
}

.grandpri-flex {
  display: flex;
}


.grandpri-image img{
  width: 200px;
  height: 200px;
}

.grandpri-item strong {
  font-size: 26px;
  color: #C72538;
  display: block;
  margin-bottom: 10px;
}

.grandpri-item p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  margin: 20px;
  padding-left: 5px;
  position: relative;
}

.grandpri-item p::before {
  content: '★';
  position: absolute;
  left: -24px;
  top: -9px;
  font-size: 28px;
  color: #C72538;
}

.grandpri-item {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.grandpri-item:hover {
  background-color: #f9f9f9;
  transform: translateY(-5px);
}


@media (max-width: 768px) {
  .grand-title-container h1 {
    font-size: 40px;
  }

  .grand-title-container h2 {
    font-size: 20px;
  }

  .grand-content {
    padding: 20px 15px;
  }

  .grand-item strong {
    font-size: 22px;
  }

  .grand-item p {
    font-size: 16px;
  }

  .learn-more-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}



/* Contacts */

.contact-section {
  display: flex;
  justify-content: space-between;
  padding: 0px 50px;
  background-color: #fff;
}

.contact-card {
  flex: 1;
  padding: 20px;
  position: relative;
  text-align: center;
  border-right: 1px solid #eaeaea;
}

.contact-card:last-child {
  border-right: none;
}

.contact-card h3 {
  font-size: 24px;
  font-weight: bold;
  color: #222;
  margin: 0px 0px 10px 0px;
}

.contact-card p {
  font-size: 18px;
  line-height: 28px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.contact-icon {
  width: 60px;
  height: auto;
  margin-bottom: 40px;
}

.contact-number {
  position: absolute;
  font-weight: 700;
  font-size: 200px;
  line-height: 200px;
  color: rgba(34, 34, 34, 0.1);
  bottom: 10px;
  right: 20px;
  opacity: 0.5;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  background: linear-gradient(303.2deg, #D9D9D9 9.69%, rgba(217, 217, 217, 0) 90.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: 'Eston', sans-serif;
}

.articles-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.articles-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.article-card {
  width: 32%;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.article-card:hover {
  transform: translateY(-5px);
}


.article-info {
  padding: 20px;
  text-align: left;
}

.article-meta {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #888;
  margin-bottom: 10px;
  font-style: italic;
}

.article-category {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #888;
}

.article-date {
  color: #c72538;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.article-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #333;
}

.article-title a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.article-title a:hover {
  color: #c72538;
}

.article-read-more {
  font-size: 14px;
  font-weight: 400;
  color: #888;
  text-decoration: none;
  display: block;
  margin-top: 20px;
}

.article-read-more:hover {
  color: #c72538;
}


.article-container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 40px 20px;
}

.article-page {
  background-color: #fff;

}

.article-page-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.article-page-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 20px;
}

.article-page-content {
  font-size: 18px;
  color: #222;
  line-height: 1.7;
}

.article-page-autor {
  font-size: 18px;
  color: #222;
  line-height: 1.7;
  text-align: end;
}

.article-paragraph {
  margin-bottom: 20px;
  font-size: 20px;
}

.article-sub-header {
  font-size: 24px;
  font-weight: 600;
  margin: 0px 0px 20px 0px;
}

.article-header {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.02em;
  margin-top: 20px;
  margin-bottom: 20px;
}



.position-list {
  padding-left: 20px;
  margin: 20px 0;
}

.position-list li {
  font-size: 20px;
  line-height: 1.6;
  text-align: start;
  margin-bottom: 10px;
  color: #222;
  list-style: none;
  position: relative;
}

.position-list li::before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px; 
  background-color: #C72538; 
  border-radius: 50%; 
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%); 
}

/* Jury */

.jury-container {
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.jury-title {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin-bottom: 40px;
  text-align: center;
}

.year-selector {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}


.jury-list {
  margin-top: 40px;
}

.jury-member {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.jury-image {
  width: 250px;
  height: 300px;
  margin-right: 20px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.jury-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.jury-image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
}

.jury-details {
  flex: 1;
}

.jury-details strong {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.02em;
  display: block;
  margin-bottom: 10px;
}

.jury-details p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  margin: 10px 0px;
}

.jury-prestige {
  margin-top: 40px;
  text-align: center;
}


@media (max-width: 768px) {
  .jury-member {
    flex-direction: column;
    align-items: flex-start;
  }

  .jury-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .jury-details strong {
    font-size: 22px;
  }
}

.articles-preview-section {
  padding: 50px 20px ;
  max-width: 1320px;
  margin: 0 auto;
  text-align: center;
}



.articles-preview-text-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0px;
}

.articles-preview-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.grand-preview-flex-container {
  position: relative;
    display: block;
}

.grand-preview-flex-container h2 {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin: 20px 10px;
}

.grand-preview-flex-container h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: rgba(34, 34, 34, 0.2);
  position: absolute;
  top: -60%;
  left: -21px;
  z-index: -1;
}

.articles-preview-flex-container {
  position: relative;
    display: block;
}

.articles-preview-flex-container h2 {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin: 10px;
}

.articles-preview-flex-container h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: rgba(34, 34, 34, 0.2);
  position: absolute;
  top: -80%;
  left: -21px;
  z-index: -1;
}

.article-card {
  width: 32%;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.article-card:hover {
  transform: translateY(-5px);
}

.article-image {
  width: 100%;
  height: 216px;
  display: block;
}

.article-info {
  padding: 20px;
  text-align: left;
}

.article-meta {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #888;
  margin-bottom: 10px;
  font-style: italic;
}

.article-category {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #888;
}

.article-date {
  color: #c72538;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.article-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #333;
}

.article-title a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.article-title a:hover {
  color: #c72538;
}

.article-read-more {
  font-size: 14px;
  font-weight: 400;
  color: #888;
  text-decoration: none;
  display: block;
  margin-top: 20px;
}

.article-read-more:hover {
  color: #c72538;
}

.apply-form-container {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.apply-form-title {
  font-weight: 700;
  color: #222222;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  margin-bottom: 40px;
  text-align: center;
}

.apply-form .form-group {
  margin-bottom: 20px;
}

.apply-form .form-group label {
  display: block;
  font-size: 18px;
  color: #555;
  margin-bottom: 8px;
}

.apply-form .form-group input,
.apply-form .form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #EAEAEA;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.apply-form .form-group input:focus,
.apply-form .form-group textarea:focus {
  border-color: #C72538;
  outline: none;
}

.apply-form .form-group textarea {
  resize: vertical;
  height: 150px;
}

.apply-form .contact-button {
  background-color: #C72538;
  color: white;
  padding: 13px 30px;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 26px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
}

.apply-form .contact-button:hover {
  background-color: #a91f2d;
}

.submission-message {
  text-align: center;
  font-size: 18px;
  color: #555;
}

.footer {
  background-color: #222222;
  color: #ffffff;
  padding: 40px 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-logo {
  flex: 1;
}

.footer-logo img {
  width: 150px;
  margin-bottom: 20px;
}

.footer-logo address {
  font-style: normal;
  line-height: 1.6;
  margin-bottom: 20px;
}

.footer-logo a {
  color: #ffffff;
  text-decoration: none;
  display: block;
  margin-bottom: 15px;
}

.footer-social{ 
  display: flex;
}

.footer-social a {
  color: #ffffff;
  font-size: 18px;
  margin-right: 15px;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #C72538;
}

.footer-links {
  flex: 1;
}

.footer-links h4 {
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #C72538;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo, .footer-links {
    margin-bottom: 20px;
  }
}